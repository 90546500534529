import * as React from 'react';
import Login from './Login';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

function WrkitRedirectSsoLogin({ ssoOnly }) {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const persistLogin = async () => {
            await dispatch({
                type: 'user/PERSIST_LOGIN',
            });
        }

        const storedLoggedIn = window.localStorage.getItem("loggedIn") === "true";

        if (storedLoggedIn) {
            persistLogin();
        }
    }, [dispatch]);

    const loggedIn = useSelector(state => state.user.loggedIn);

    React.useEffect(() => {
        if (loggedIn) {
            onLoginSuccess();
        }
    }, [loggedIn]);

    const onLoginSuccess = async () => {

        const response = await dispatch({
            type: 'user/WRKIT_RE_DIRECT',
        });
        if (response?.redirect) {
            window.location.href = response.redirect;
        }
        return response;
    }

    return <Login ssoOnly={ssoOnly} onLoginSuccess={onLoginSuccess} />;
}

WrkitRedirectSsoLogin.propTypes = {
    ssoOnly: bool
};

export default WrkitRedirectSsoLogin;